const form = document.getElementById("passwordForm");
function submitForm(event) {
	const password = "No1butnever"; // Change this to your desired password
	const passwordInput = document.getElementById("passwordInput").value;
	event.preventDefault();
	if (passwordInput === password) {
		document.getElementById("passwordForm").classList.add("hidden");
		document.getElementById("content").classList.remove("hidden");
	} else {
		alert("Incorrect password. Please try again.");
	}
}
form.addEventListener("submit", submitForm);
